<template>
  <div>
    <el-form
      ref="requestForm"
      :model="request"
      label-width="100px"
      label-position="left"
      size="mini"
      :rules="rules"
    >
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item prop="id" label="ID">
            <el-input
              v-model="request.id"
              placeholder="请输入内容"
              clearable
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item prop="type" label="Type">
            <el-select
              v-model="request.type"
              placeholder="RequestType"
              style="width: 100%;"
            >
              <el-option
                v-for="type in types"
                :key="type"
                :label="type"
                :value="type"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item prop="status" label="Status">
            <el-select v-model="request.status" style="width: 100%;">
              <el-option
                v-for="status in statuses"
                :key="status"
                :label="status"
                :value="status"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item prop="priority" label="Priority">
            <el-select v-model="request.priority" style="width: 100%;">
              <el-option
                v-for="priority in priorities"
                :key="priority"
                :label="priority"
                :value="priority"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="Customer">
            <el-select
              v-model="request.customer"
              placeholder="Customer"
              style="width: 100%;"
            >
              <el-option
                v-for="customer in customers"
                :key="customer.name"
                :label="customer.name"
                :value="customer.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item label="Assignee">
            <el-select v-model="request.assignee" style="width: 100%;">
              <el-option
                v-for="assignee in assignees"
                :key="assignee.username"
                :value="assignee.username"
                :label="assignee.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item required label="Title" prop="title">
            <el-input v-model="request.title" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="Description">
            <el-input
              type="textarea"
              v-model="request.description"
              clearable
              :autosize="{ minRows: 2, maxRows: 8 }"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="Attachment">
            <el-upload
              style="float:left;"
              ref="upload"
              :headers="{ pstoken: pstoken }"
              :action="baseUrl + 'request/file_upload'"
              :data="{ id: request.id }"
              :before-upload="handleBeforeUpload"
              :file-list="fileList"
              :on-success="handleOnSuccess"
              :on-remove="handleOnRemove"
              :on-preview="handlePreviewDownload"
            >
              <el-button size="mini" type="primary">Click to upload</el-button>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <div style="width: 100%; float:left; height:40px; line-height:40px;">
            <el-button
              style="float:right;"
              type="primary"
              size="small"
              @click="save"
              >Confirm</el-button
            >
            <el-button
              style="float:right; margin-right: 10px;"
              size="small"
              @click="cancel"
              >Cancel</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import http from "../utils/http";
import config from "../utils/config";
export default {
  name: "RequestDetail",
  components: {},
  props: {
    req: {},
  },
  data() {
    return {
      baseUrl: http.baseURL,
      request: {
        _id: "",
        id: "",
        type: "Service Request",
        priority: "P3",
        customer: "Internal",
        assignee: "",
        status: "New",
        title: "",
        description: "",
        logs: [],
        attachments: [],
      },
      customers: [],
      types: config.requestTypes,
      statuses: config.requestStatuses,
      priorities: config.priorities,
      assignees: [],
      pstoken: localStorage.getItem("pstoken"),
      fileList: [],
      rules: {
        title: [
          { required: true, message: "please input title", trigger: "blur" },
          {
            min: 4,
            max: 100,
            message: "length is between 4 to 100 letters",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    if (JSON.stringify(this.req) != "{}") {
      this.request._id = this.req._id;
      this.request.id = this.req.id;
      this.request.title = this.req.title;
      this.request.description = this.req.description;
      this.request.type = this.req.type;
      this.request.attachments = this.req.attachments;
      this.request.assignee = this.req.assignee;
      this.request.priority = this.req.priority;
      this.request.customer = this.req.customer;
      this.request.status = this.req.status;
      this.request.logs = this.req.logs;
      this.req.attachments.forEach((value) => {
        this.fileList.push({
          name: value,
        });
      });
    }
  },
  mounted() {
    this.getCustomers();
    if (typeof this.request.id == "undefined" || this.request.id == "") {
      this.request.id = this.generateRequestId();
    }
    this.getInternalUsers();
  },
  methods: {
    save() {
      this.$refs.requestForm.validate((valid) => {
        if (valid) {
          http.put("/request/save", this.request).then((res) => {
            if (res.data.status === 1) {
              this.$message({
                message: "Request was submitted successfully!",
                type: "success",
              });
              this.$emit("confirm");
            } else {
              this.$message.error("Error happened, please try again later.");
            }
          });
        }
      });
    },
    cancel() {
      this.fileList.splice(0, this.fileList.length);
      this.request = {};
      this.$emit("cancel");
    },
    handleOnSuccess(response, file, fileList) {
      this.request.attachments = [];
      fileList.forEach((value) => {
        this.request.attachments.push(value.name);
      });
    },
    handleOnRemove(file, fileList) {
      this.request.attachments = [];
      fileList.forEach((value) => {
        this.request.attachments.push(value.name);
      });
    },
    handleBeforeUpload(file) {
      if (this.request.attachments.indexOf(file.name) > -1) {
        this.$message.error(file.name + " already exists.");
        return false;
      }
      if (file.size > 600000000) {
        this.$message.error("file size cannot be more than 500M");
        return false;
      }
    },
    handlePreviewDownload(file) {
      var url =
        http.baseURL +
        "request/download?" +
        "requestId=" +
        this.request.id +
        "&fileName=" +
        file.name +
        "&pstoken=" +
        localStorage.getItem("pstoken");
      window.open(url);
    },
    generateRequestId() {
      var date = new Date();
      var month = date.getMonth();
      var date2 = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();
      var str =
        "R" +
        date.getFullYear() +
        (month + 1 < 10 ? "0" + (month + 1) : month + 1) +
        (date2 < 10 ? "0" + date2 : date2) +
        (hour < 10 ? "0" + hour : hour) +
        (minute < 10 ? "0" + minute : minute) +
        (second < 10 ? "0" + second : second);

      return str;
    },
    getCustomers() {
      http.get("/customer/getCustomer").then((res) => {
        if (res.data.status === 1) {
          this.customers.splice(0, this.customers.length);
          res.data.data.forEach((value) => {
            this.customers.push(value);
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getInternalUsers() {
      http.get("/user/internal").then((res) => {
        if (res.data.status === 1) {
          this.assignees = res.data.data;
        } else {
          this.$message.error("Error happened!");
        }
      });
    },
  },
};
</script>
<style scoped></style>
