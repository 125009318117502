<template>
  <div>
    <div class="header">
      <el-button type="primary" size="mini" @click="handleCreateClick"
        >Create</el-button
      >
      <el-input
        style="width: 180px; margin-left: 5px;"
        placeholder="search"
        prefix-icon="el-icon-search"
        size="mini"
        v-model="filter.keyword"
        clearable
      >
      </el-input>
      <el-select
        clearable
        multiple
        collapse-tags
        v-model="filter.status"
        size="mini"
        placeholder="Status"
        style="margin-left: 5px;width: 180px;"
      >
        <el-option
          v-for="item in statuses"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        multiple
        collapse-tags
        clearable
        v-model="filter.customer"
        placeholder="Customer"
        size="mini"
        style="width: 200px; margin-left: 5px;"
      >
        <el-option
          v-for="customer in customers"
          :key="customer.name"
          :label="customer.name"
          :value="customer.name"
        >
        </el-option>
      </el-select>
      <el-select
        multiple
        collapse-tags
        v-model="filter.assignee"
        clearable
        placeholder="owner"
        size="mini"
        style="width: 200px; margin-left: 5px;"
      >
        <el-option
          v-for="assignee in assignees"
          :key="assignee.username"
          :value="assignee.username"
          :label="assignee.name"
        ></el-option>
      </el-select>
      <el-button
        icon="el-icon-filter4"
        type="primary"
        size="mini"
        style="margin-left: 5px;"
        @click="showDateRange = true"
      ></el-button>
      <el-button
        icon="el-icon-lightning-full"
        size="mini"
        @click="query"
        type="primary"
        style="margin-left: 5px;"
      ></el-button>
    </div>
    <el-table
      border
      :data="selectedRequests"
      size="mini"
      id="request_table"
      style="width: 100%; float:left; margin-top: 10px;"
      :height="tableHeight"
      @expand-change="handleExpandChange"
      :row-class-name="tableRowClassName"
      :default-sort="{ prop: 'status', order: 'descending' }"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form
            size="mini"
            label-position="top"
            style="margin-left: 50px; margin-right: 50px;"
          >
            <el-form-item label="Title">
              <div style="background: #F2F6FC;">{{ props.row.title }}</div>
              <!-- <el-input v-model="props.row.title" disabled></el-input> -->
            </el-form-item>
            <el-form-item label="Description">
              <el-input
                disabled
                v-model="props.row.description"
                :autosize="{ minRows: 2, maxRows: 10 }"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="Logs">
              <div
                v-for="log in props.row.logs"
                :key="log.dateTime"
                style="font-size: 13px;"
              >
                <span
                  >{{
                    new Date(log.dateTime).toLocaleString("chinese", {
                      hour12: false,
                    })
                  }}
                  {{ "   " + "|" + "   " }} {{ log.user }}</span
                >
                <el-input
                  type="textarea"
                  disabled
                  :autosize="{ minRows: 1, maxRows: 20 }"
                  v-model="log.action"
                ></el-input>
              </div>
              <el-popover
                trigger="click"
                v-model="logInputVisible"
                placement="top"
                width="500"
              >
                <el-input
                  type="textarea"
                  size="mini"
                  v-model="log"
                  :autosize="{ minRows: 2, maxRows: 20 }"
                  placeholder="Please input update"
                ></el-input>
                <el-button
                  size="mini"
                  style="margin-top: 5px; float: right;"
                  type="primary"
                  @click="handleLogConfirm(props.row)"
                  >Confirm</el-button
                >
                <el-button
                  size="mini"
                  style="margin-top: 5px; float: right; margin-right: 10px;"
                  @click="(logInputVisible = false), (log = '')"
                  >Cancel</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-edit"
                  style="marginTop: 10px;"
                  slot="reference"
                  >Add Log</el-button
                >
              </el-popover>
            </el-form-item>
            <el-form-item label="Attachments">
              <el-upload
                :headers="{ pstoken: pstoken }"
                :on-preview="(file) => handlePreviewDownload(file, props.row)"
                :on-remove="
                  (file, fileList) => handleOnRemove(file, fileList, props.row)
                "
                ref="upload"
                :before-upload="(file) => handleBeforeUpload(file, props.row)"
                :before-remove="handleBeforeRemove"
                :file-list="props.row.fileList"
                :action="baseUrl + 'request/file_upload'"
                :data="{ id: props.row.id }"
                :on-success="
                  (response, file, fileList) =>
                    hanleOnSuccess(file, fileList, props.row)
                "
              >
                <el-button size="mini" type="primary" icon="el-icon-upload"
                  >Click to upload</el-button
                >
              </el-upload>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="ID" prop="id" sortable width="130px">
      </el-table-column>
      <el-table-column label="Title" prop="title" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        sortable
        :show-overflow-tooltip="true"
        label="Status"
        prop="status"
      >
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        label="Created"
        prop="creationTime"
      ></el-table-column>
      <el-table-column
        label="Creator"
        prop="creator.username"
      ></el-table-column>
      <el-table-column label="Customer" prop="customer"></el-table-column>
      <el-table-column
        label="Owner"
        prop="assignee"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="Type"
        prop="type"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="Priority" prop="priority"></el-table-column>
      <el-table-column label="Action" width="100px">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            circle
            size="mini"
            plain
            @click="editRequest(scope.row)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            circle
            size="mini"
            plain
            @click="deleteRequest(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div
      style="margin-top: 10px; height: 30px; float:left; line-height: 30px; width: 100%;"
    >
      <el-button
        size="mini"
        type="success"
        style=" float:left;"
        icon="el-icon-download"
        @click="exportExcel"
        >Export</el-button
      >
      <span style="color: #606266; font-size:13px;"
        >In total: {{ selectedRequests.length }}</span
      >
    </div>
    <el-dialog :title="title" :visible.sync="showDialog">
      <request-detail
        v-if="showDialog"
        @confirm="handleConfirm"
        @cancel="handleCancel"
        :req="request"
      ></request-detail>
    </el-dialog>
    <el-dialog title="Select Date Range" :visible.sync="showDateRange">
      <date-range
        :dateRange="dateRange"
        @cancel="showDateRange = false"
        @confirm="handleDateRangeConfirm"
      ></date-range>
    </el-dialog>
  </div>
</template>
<script>
import http from "../utils/http";
import config from "../utils/config";
import RequestDetail from "./RequestDetail.vue";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import DateRange from "./DateRange.vue";
export default {
  name: "Request",
  components: {
    RequestDetail,
    DateRange,
  },
  data() {
    return {
      baseUrl: http.baseURL,
      showDateRange: false,
      dateRange: [],
      startDate: "",
      endDate: "",
      request: {},
      title: "New Request",
      pstoken: localStorage.getItem("pstoken"),
      logInputVisible: false,
      log: "",
      customers: [],
      filter: {
        keyword: "",
        status: [],
        customer: [],
        assignee: [],
      },
      types: config.requestTypes,
      statuses: config.requestStatuses,
      priorities: config.priorities,
      assignees: [],
      fileName: "",
      requests: [],
      rules: {
        title: [
          { required: true, message: "Please input title", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "length is between 3 t0 50 characters",
            trigger: "blur",
          },
        ],
      },
      showDialog: false,
      tableHeight: window.innerHeight - 200,
      tableMaxHeight: window.innerHeight - 160,
    };
  },
  computed: {
    selectedRequests() {
      var kw = this.filter.keyword.toLowerCase();
      var st = this.filter.status;
      var cu = this.filter.customer;
      var as = this.filter.assignee;
      var round1 = this.requests.filter((value) => {
        return this.filter.keyword == ""
          ? true
          : value.id.indexOf(kw) > -1 ||
              value.title.toLowerCase().indexOf(kw) > -1 ||
              value.description.toLowerCase().indexOf(kw) > -1;
      });
      var round2 = round1.filter((value) => {
        return st.length == 0 ? true : st.indexOf(value.status) > -1;
      });
      var round3 = round2.filter((value) => {
        return cu.length == 0 ? true : cu.indexOf(value.customer) > -1;
      });
      var round4 = round3.filter((value) => {
        return as.length == 0 ? true : as.indexOf(value.assignee) > -1;
      });
      var round5 = round4.filter((value) => {
        return this.startDate != ""
          ? new Date(value.creationTime) >= this.startDate
          : true;
      });
      var round6 = round5.filter((value) => {
        return this.endDate != ""
          ? new Date(value.creationTime) <= this.endDate
          : true;
      });
      return round6;
    },
  },
  mounted() {
    this.getUser();
    this.query();
    this.getCustomers();
  },
  methods: {
    handleDateRangeConfirm(value) {
      // console.log(value);
      if (value != null && value.length > 0) {
        this.dateRange = value;
        this.startDate = new Date(value[0]);
        this.endDate = new Date(value[1]);
        this.endDate.setDate(this.endDate.getDate() + 1);
      } else {
        (this.dateRange = []), (this.startDate = ""), (this.endDate = "");
      }
      // console.log(this.startDate);
      // console.log(this.endDate);
      // console.log(this.startDate < this.endDate);
      this.showDateRange = false;
    },
    tableRowClassName({ row }) {
      if (row.status === "New") {
        return "open-row";
      } else if (row.status === "Closed") {
        return "closed-row";
      }
      return "";
    },
    handleExpandChange(row) {
      row.fileList.splice(0, row.fileList.length);
      row.attachments.forEach((value) => {
        row.fileList.push({ name: value });
      });
    },
    getCustomers() {
      http.get("/customer/getCustomer").then((res) => {
        if (res.data.status === 1) {
          this.customers.splice(0, this.customers.length);
          res.data.data.forEach((value) => {
            this.customers.push(value);
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleCreateClick() {
      this.title = "New Request";
      this.request = {};
      this.showDialog = true;
    },
    handleLogConfirm(row) {
      http
        .post("/request/addLog", { requestId: row.id, log: this.log })
        .then((res) => {
          if (res.data.status === 1) {
            this.logInputVisible = false;
            row.logs.push(res.data.data.log);
            this.log = "";
            this.$message({
              type: "success",
              message: "Log was added successfully.",
            });
          } else {
            this.$message.error(res.data.data.message);
          }
        });
    },
    editRequest(row) {
      this.title = "Edit Request";
      this.request = row;
      this.showDialog = true;
    },
    deleteRequest(scope) {
      http.post("/request/delete", { _id: scope.row._id }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: "Deleted successfully",
          });
          this.requests.splice(this.requests.indexOf(scope.row), 1);
        }
      });
    },
    handleConfirm() {
      this.showDialog = false;
      this.query();
    },
    handleCancel() {
      this.showDialog = false;
    },
    handleBeforeUpload(file, row) {
      // console.log("beforeUpload...");
      // console.log(file);
      // console.log(row);
      // console.log(row.attachments);
      // console.log(file.name);
      // console.log(row.attachments.indexOf(file.name));
      if (row.attachments.indexOf(file.name) > -1) {
        // console.log("hello..........");
        this.$message.error(file.name + " already exists.");
        return false;
      }
    },
    hanleOnSuccess(file, fileList, row) {
      // console.log("on success..");
      // console.log(file);
      // console.log(fileList);
      var temp = [];
      fileList.forEach((value) => {
        if (
          temp.findIndex((v) => {
            return v.name == value.name;
          }) < 0
        ) {
          temp.push(value);
        }
      });
      fileList = temp;
      // console.log(fileList);
      var attachments = [];
      fileList.forEach((value) => {
        attachments.push(value.name);
      });
      row.attachments = attachments;
      http
        .put("/request/updateAttachment", {
          id: row.id,
          attachments: attachments,
        })
        .then((res) => {
          if (res.data.status === 1) {
            this.$message({
              type: "success",
              message: "File was uploaded successfully.",
            });
          }
        });
    },
    handleBeforeRemove(file, fileList) {
      console.log("handleBeforeRemove...");
      console.log(file);
      console.log(fileList);
    },
    handleOnRemove(file, fileList, row) {
      var t = fileList.filter((value) => {
        return value.name == file.name;
      });
      if (t.length == 0) {
        http.post("/request/deleteFile", {
          fileName: file.name,
          id: row.id,
        });
        var attachments = [];
        fileList.forEach((value) => {
          attachments.push(value.name);
        });
        http
          .put("/request/updateAttachment", {
            id: row.id,
            attachments: attachments,
          })
          .then((res) => {
            if (res.data.status === 1) {
              this.$message({
                type: "success",
                message: file.name + " was deleted successfully",
              });
              row.attachments = attachments;
            }
          });
      }
    },
    handlePreviewDownload(file, row) {
      var url =
        http.baseURL +
        "request/download?" +
        "requestId=" +
        row.id +
        "&fileName=" +
        file.name +
        "&pstoken=" +
        localStorage.getItem("pstoken");
      window.open(url);
    },
    getUser() {
      http.post("/user/getUserByKeyword").then((res) => {
        if (res.data.status == 1) {
          this.assignees.splice(0, this.assignees.length);
          res.data.data.forEach((user) => {
            if (user.type == "internal") {
              this.assignees.push(user);
            }
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    query() {
      http.post("/request/query").then((res) => {
        if (res.data.status === 1) {
          this.requests = res.data.data;
          this.requests.forEach((request) => {
            if (
              request.creationTime &&
              typeof request.creationTime != "undefined"
            ) {
              request.creationTime = new Date(
                request.creationTime
              ).toLocaleString("chinese", { hour12: false });
            }

            var fileList = [];
            if (typeof request.attachments != "undefined") {
              request.attachments.forEach((a) => {
                fileList.push({ name: a });
              });
              request.fileList = fileList;
            }
          });
        }
      });
    },
    diaglogClose() {
      this.request = {};
      this.$refs.upload.clearFiles();
    },
    beforeRemove(file) {
      this.attachments.splice(this.attachments.indexOf(file.name), 1);
      http
        .post("/request/deleteFile", {
          fileName: file.name,
          id: this.request.id,
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.$message({
              type: "success",
              message: "deleted successfully",
            });
          } else {
            return false;
          }
        });
    },
    exportExcel() {
      /* generate workbook object from table */
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#request_table")
      );
      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "request_list.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
  },
};
</script>
<style scoped>
.header {
  width: 1000px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  float: left;
  /* margin-top: -10px; */
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
<style>
.el-table .closed-row {
  /* background: rgb(216, 216, 213); */
  color: rgb(194, 192, 192);
}

.el-table .open-row {
  color: rosybrown;
}

.el-table .success-row {
  background: #def1d5;
}
</style>
