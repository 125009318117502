<template>
  <div>
    <el-date-picker
      v-model="value"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="To"
      start-placeholder="Start Date"
      end-placeholder="End Date"
      :picker-options="pickerOptions"
    >
    </el-date-picker>
    <div style="width: 100%; height: 40px; margin-top: 50px;">
      <el-button
        type="primary"
        size="mini"
        style="float: right;"
        @click="handleConfirm"
        >Confirm</el-button
      >
      <el-button
        @click="handleCancel"
        size="mini"
        style="float: right; margin-right: 10px;"
        >Cancel</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "DateRange",
  props: {
    dateRange: [],
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "Recent 1 week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Recent 1 month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Recent 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value: "",
    };
  },
  mounted() {
    if (this.dateRange && this.dateRange != null && this.dateRange.length > 0) {
      this.value = this.dateRange;
    } else {
      this.value = [];
    }
  },
  methods: {
    // handleChange() {
    //   console.log("handleChange...");
    //   console.log(this.value);
    // },
    // handleBlur() {
    //   console.log("handleBlur...");
    //   console.log(this.value);
    // },
    handleConfirm() {
      this.$emit("confirm", this.value);
    },
    handleCancel() {
      this.$emit("cancel");
    },
  },
};
</script>
<style scoped></style>
